import { autorun, computed, configure, makeAutoObservable, toJS } from 'mobx';
import {
    OptionsVariationsType,
    ProductEntity,
    ProductPaymentPlan,
    ProductType,
    VariationType,
} from './types';
import { eventsService } from 'client/widget-components/services/events-service/eventsService';
import {
    createProductPaymentPlanOptionList,
    decodeUniqueKey,
    encodeUniqueKey,
} from './utils';
import { prepareAndSendGAItemViewEvent } from 'client/widget-components/ecom/stores/productStateAnalyticsService';

configure({ isolateGlobalState: true });
export const DEFAULT_VARIATION_ID = 'defvar12';

export class ProductState {
    product: ProductType;
    dropdownOptions: OptionsVariationsType = {};
    paymentPlanOptions: ProductPaymentPlan[] = [];
    variations: Map<string, VariationType> = new Map();
    selectedVariationKey: string = '';
    selectedPaymentPlan: ProductPaymentPlan | null = null;

    constructor(product: ProductType) {
        makeAutoObservable(this, {
            selectedVariation: computed,
            productData: computed,
        });
        this.product = product;
        this.dropdownOptions = product.options;
        this.paymentPlanOptions = createProductPaymentPlanOptionList(product);
        this.selectedPaymentPlan = this.paymentPlanOptions[0];
        this.initVariations(product.variations);
        this.prepareAndSendGMEvent();
    }

    private prepareAndSendGMEvent() {
        if (!this.productData) {
            return;
        }
        prepareAndSendGAItemViewEvent(this.productData);
    }

    initVariations(variations: VariationType[] = []) {
        const isVariationsPriceDifferentFromProductPrice = !!variations.find(
            (variation) => variation.price !== this.product.price
        );
        const shouldModifyLabels =
            Object.keys(this.dropdownOptions).length === 1 &&
            isVariationsPriceDifferentFromProductPrice;
        variations.forEach((variation) => {
            const optionValues = variation.selected_options_values;
            if (optionValues) {
                this.variations.set(encodeUniqueKey(optionValues), variation);
                if (shouldModifyLabels) {
                    const optionToVal = Object.entries(
                        JSON.parse(optionValues)
                    );
                    const [optionKey, optionValue] = optionToVal[0];
                    const current = this.dropdownOptions[optionKey].find(
                        (item) => item.value === optionValue
                    );
                    if (current) {
                        current.label = `${current.label} - ${variation.displayed_price}`;
                    }
                }
            }
        });
        // init default values
        if (!this.selectedVariationKey) {
            const validVariation = variations.find(
                (variant) => variant.selected_options_values
            )?.selected_options_values;
            if (validVariation) {
                const variationObj = JSON.parse(validVariation);
                this.selectedVariationKey = encodeUniqueKey(variationObj);
            }
        }
    }

    getSelectedField(key: string) {
        const fieldValue = decodeUniqueKey(this.selectedVariationKey)[key];
        if (fieldValue && this.dropdownOptions[key]) {
            return this.dropdownOptions[key].find(
                (op) => op.value === fieldValue
            );
        }
    }

    get selectedVariation() {
        return this.variations.get(this.selectedVariationKey);
    }

    // Merges product & current variation data
    // & payment plan prices
    get productData(): ProductEntity {
        const selectedVariation = toJS(this.selectedVariation);
        const itemId = this.getItemId(selectedVariation);
        if (selectedVariation) {
            const image =
                selectedVariation.images[0]?.image || this.product.image;

            return {
                ...this.product,
                ...selectedVariation,
                ...this.getSelectedVariationDiscountPrice(selectedVariation),
                image,
                itemId,
                productId: this.product.identifier,
            };
        }

        return {
            ...this.product,
            ...this.getDiscountPrice(this.product),
            itemId,
            productId: this.product.identifier,
        };
    }

    getDiscountPrice = ({
        price,
        displayed_price,
    }: ProductType): {
        price: number;
        displayed_price: string;
    } => {
        if (this.selectedPaymentPlan) {
            return {
                price: this.selectedPaymentPlan.plan_price,
                displayed_price: this.selectedPaymentPlan.plan_displayed_price,
            };
        }

        return {
            price,
            displayed_price,
        };
    };

    getSelectedVariationDiscountPrice = ({
        price,
        displayed_price,
        plans_prices,
    }: VariationType): {
        price: number;
        displayed_price: string;
    } => {
        const variationPaymentPlanPrices = plans_prices.find(
            (planPrices) => planPrices.id === this.selectedPaymentPlan?.id
        );

        if (variationPaymentPlanPrices) {
            return {
                price: variationPaymentPlanPrices.price,
                displayed_price: variationPaymentPlanPrices.displayed_price,
            };
        }

        return {
            price,
            displayed_price,
        };
    };

    selectPaymentPlanOption = (option: ProductPaymentPlan) => {
        this.selectedPaymentPlan = option;
    };

    updateVariation(variationName: string, variationValue: string) {
        if (this.selectedVariation?.selected_options_values) {
            const optionValues = JSON.parse(
                this.selectedVariation.selected_options_values
            );
            this.selectedVariationKey = encodeUniqueKey({
                ...optionValues,
                [variationName]: variationValue,
            });
            this.updatePaymentPlanOptions();
            this.prepareAndSendGMEvent();
        }
    }

    updatePaymentPlanOptions = () => {
        if (!this.selectedVariation) {
            return;
        }

        const selectedVariation = this.selectedVariation;

        this.paymentPlanOptions = this.paymentPlanOptions.map((plan) => {
            const paymentPlanPricesForSelectedVariation =
                selectedVariation.plans_prices.find(
                    (planPrices) => planPrices.id === plan.id
                );
            return {
                ...plan,
                plan_price:
                    paymentPlanPricesForSelectedVariation?.price ||
                    selectedVariation.price,
                plan_displayed_price:
                    paymentPlanPricesForSelectedVariation?.displayed_price ||
                    selectedVariation.displayed_price,
            };
        });
    };

    onSelectedValuesChange(
        callback: (selectedVariation?: ProductEntity) => void
    ) {
        const imageToIndex = this.product.images.reduce(
            (result: { [key: string]: number }, image, index) => ({
                ...result,
                [image.image]: index,
            }),
            {}
        );
        autorun(() => {
            const variationImage = this.productData.image;
            const newIndex = imageToIndex[variationImage];
            eventsService.dispatch(
                'selected-image-changed',
                'dynamic_page_collection.images',
                { newIndex, newSrc: variationImage }
            );
            callback(this.productData);
        });
    }

    private getItemId(currentVariation?: VariationType) {
        const productId = this.product.identifier || this.product.sku;
        const productExternalId = this.product.external_id || '';
        const {
            identifier: variationId = DEFAULT_VARIATION_ID,
            external_id: variationExternalId = '',
        } = currentVariation || this.defaultVariation || {};

        return `${productId}_${variationId}:${productExternalId}_${variationExternalId}`;
    }

    /**
     * Get the variation which represents the product without selected choices
     */
    private get defaultVariation() {
        return this.product.variations.find(
            (variation) => !variation.selected_options_values
        );
    }
}
