import React from 'react';
import { RuntimeWidget } from 'client/types';
import { context } from '@shared-services/context-service';
import { ecomObserver } from '@shared-services/ecom-service';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles';
import { Div } from 'client/widget-components/basicComponents';
import {
    OptionsRenderType,
    OV_WidgetStyles,
    RuntimeOptionsVariationsProps,
} from './types';
import Dropdown from 'client/widget-components/components/Dropdown';
import { normalizeStyles } from 'client/widgets/common/styles/migration/stylesMigration';
import RadioButtonsGroup from 'client/widget-components/components/RadioButtonsGroup';
import { ResponsiveStylesProp } from '../../widget-components/responsive-styles/responsiveStylesService';
import withErrorBoundary from "../../widget-components/components/ErrorBoundary/withErrorBoundary";

const RuntimeOptionsVariations: RuntimeWidget<
    RuntimeOptionsVariationsProps
> = ({
    productState,
    editorEmptyMessage,
    updateProduct,
    dropdownPlaceholder,
    renderType,
    _styles,
}) => {
    const styles = normalizeStyles({
        stylesInput: _styles,
        monitorKey: 'options.variations',
    }) as OV_WidgetStyles;

    const ovEntries = Object.entries(productState?.dropdownOptions || {});
    if (ovEntries.length === 0) {
        if (context.isEditor && editorEmptyMessage) {
            return <div>{editorEmptyMessage}</div>;
        }
        return <div />;
    }
    const InnerComponent = getOptionsRenderer(renderType);

    return (
        <Div
            styles={[wrapperDefaultStyles, styles?.wrapper]}
            data-auto='options-variations'
            data-grab='options-variations'
            className='options-variations'
        >
            {ovEntries.map(([option, values]) => (
                <InnerComponent
                    key={`d_option_${option}`}
                    selectedValue={productState.getSelectedField(option)}
                    options={values}
                    title={option}
                    onChange={(selectedVal) =>
                        updateProduct(option, selectedVal)
                    }
                    direction={styles?.wrapper?.common?.direction}
                    dropdownPlaceholder={dropdownPlaceholder}
                    styles={styles}
                />
            ))}
        </Div>
    );
};

const wrapperDefaultStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        width: '100%',
        padding: '4px',
        gap: '18px',
        border: '0px solid',
    },
};

const getOptionsRenderer = (renderType?: OptionsRenderType) => {
    switch (renderType) {
        case OptionsRenderType.RADIOBUTTON:
            return RadioButtonsGroup;
        case OptionsRenderType.DROPDOWN:
            return Dropdown;
        default:
            return Dropdown;
    }
};

export default withErrorBoundary({
    Comp: ecomObserver(RuntimeOptionsVariations),
    componentName: 'RuntimeOptionsVariations',
    additionalInfoToLog: {
        tag: 'native-ecom'
    },
});